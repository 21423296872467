$(document).ready(initPage);

function initPage() {
  function updateScrollClass() {
    if ($(window).scrollTop() > 0) {
      $('body').addClass('is-scrolled');
    } else {
      $('body').removeClass('is-scrolled');
    }
  }

  updateScrollClass();

  $(window).scroll(function () {
    updateScrollClass();
  });

  $('.header-toggler').click(function(){
    $('body').toggleClass('is-expanded-menu');
  });

  $('a.smlink').on('click', function(e) {
    e.preventDefault();

    const target = this.hash;
    const $target = $(target);
    const headerHeight = $('.header').outerHeight();
    const topValue = $target.offset().top - headerHeight;
    $('html, body').stop().animate({
      'scrollTop': topValue
    }, 500, 'swing', function () {
      history.pushState(null, null, target);
    });

    $('body').removeClass('is-expanded-menu');
  });
}
